import React from "react";
import AIOBOOKINGLOGO from "../assets/images/svg/LOGO AIO BOOKING-webp 1.webp";
import { BsThreads } from "react-icons/bs";
import { BsInstagram } from "react-icons/bs";
import { BsFacebook } from "react-icons/bs";

export default function Footer() {
  return (
    <>
      <footer className="py-8">
        <div className="container">
          <div className="grid md:grid-cols-12 items-center">
            <div className="md:col-span-3">
              <a href="/" className="logo-footer">
                <img
                  src={AIOBOOKINGLOGO}
                  className="md:ms-0 mx-auto w-48"
                  alt=""
                />
              </a>
            </div>

            <div className="md:col-span-5 md:mt-0 mt-8">
              <div className="text-center">
                <p className="text-gray-400">
                  © {new Date().getFullYear()} AIOBOOKING. All Rights Reserved.
                </p>
              </div>
            </div>

            <div className="md:col-span-4 md:mt-0 mt-8">
              <ul className="list-none foot-icon md:text-end text-center">
                <li className="inline ms-1">
                  <a
                    href="https://web.facebook.com/profile.php?id=61555126029949"
                    target="_blank"
                    rel="noreferrer"
                    className="btn btn-icon btn-sm border rounded-md border-slate-700 dark:border-slate-800 hover:border-blue-600 bg-slate-800 dark:bg-gray-900 hover:bg-blue-600 dark:hover:bg-blue-600 text-gray-400 hover:text-white"
                  >
                    <BsFacebook />
                  </a>
                </li>
                <li className="inline ms-1">
                  <a
                    href="https://www.instagram.com/aiobooking/"
                    target="_blank"
                    rel="noreferrer"
                    className="btn btn-icon btn-sm border rounded-md border-slate-700 dark:border-slate-800 hover:border-blue-600 bg-slate-800 dark:bg-gray-900 hover:bg-blue-600 dark:hover:bg-blue-600 text-gray-400 hover:text-white"
                  >
                    <BsInstagram />
                  </a>
                </li>
                <li className="inline ms-1">
                  <a
                    href="https://www.threads.net/@aiobooking"
                    target="_blank"
                    rel="noreferrer"
                    className="btn btn-icon btn-sm border rounded-md border-slate-700 dark:border-slate-800 hover:border-blue-600 bg-slate-800 dark:bg-gray-900 hover:bg-blue-600 dark:hover:bg-blue-600 text-gray-400 hover:text-white"
                  >
                    <BsThreads />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
