import React from "react";
import { IoCarSportOutline } from "react-icons/io5";
import { IoBoatOutline } from "react-icons/io5";
import { IoBusinessOutline } from "react-icons/io5";
import { IoRadioOutline } from "react-icons/io5";
import { IoColorPaletteOutline } from "react-icons/io5";
import { IoDesktopSharp } from "react-icons/io5";

const services = [
  {
    title: "Activity Booking Engine",
    icon: <IoCarSportOutline />,
    subtext:
      "Booking Engine for travel operators, activity providers, and professional agents. Each day, dedicate different pricing and inventory to your multi-session products or services.",
  },
  {
    title: "Ferry Booking Engine",
    icon: <IoBoatOutline />,
    subtext:
      "Ferry Booking Engine is a ferry-specific booking engine that helps you to maximize your internet sales, income, and sales strategy for your business.",
  },
  {
    title: "Accomodation Booking Engine",
    icon: <IoBusinessOutline />,
    subtext:
      "Accomodation Booking Engine is a booking engine designed to boost online sales and income for lodging businesses by improving direct booking and conversion rates.",
  },
  {
    title: "Channel Manager",
    icon: <IoRadioOutline />,
    subtext:
      "The solution automates inventory availability and rates synchronization with OTAs, PMS, GDS, or other third-party software for online accommodation business channels.",
  },
  {
    title: "Website Development",
    icon: <IoDesktopSharp />,
    subtext:
      "Assist your business in creating the ideal brand concept for your website, which is the initial stage in determining the type of interface for your business's website.",
  },
  {
    title: "Digital Creative Agency",
    icon: <IoColorPaletteOutline />,
    subtext:
      "AioCreative.id is a division of a digital creative agency that provides digital services such as social media management and digital advertising. We have a crew that is extremely devoted, creative, analytical, and works to meet deadlines.",
  },
];

export default function WhatWeDo() {
  return (
    <>
      <section className="relative md:py-24 py-16 active" id="whatwedo">
        <div className="container lg mx-auto">
          <div className="grid grid-cols-1 lg:grid-cols-2 md:grid-cols-2 pb-8 items-center">
            <div>
              <h6 className="text-blue-600 text-base font-medium uppercase mb-2">
                What We Do ?
              </h6>
              <h3 className="mb-4 md:text-2xl text-xl font-semibold dark:text-white md:mb-0">
                Perfect Solution For Your <br /> Booking Management
              </h3>
            </div>

            <div>
              <p className="text-slate-400 dark:text-slate-300 max-w-xl">
                Our conversion-focused booking technology, creative digital
                marketing, and cutting-edge web design are your toolkit to
                maximize your direct bookings.
              </p>
            </div>
          </div>

          <div className="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 gap-6">
            {services.map((service, key) => (
              <div
                key={key}
                className={`features p-6 ${
                  key % 2 === 0
                    ? "hover:shadow-xl hover:shadow-slate-100 dark:hover:shadow-slate-800"
                    : "shadow-xl shadow-slate-100 dark:shadow-slate-800"
                } transition duration-500 rounded-3xl mt-8`}
              >
                <div className="w-20 h-20 bg-blue-600/5 text-blue-600 rounded-xl text-3xl flex align-middle justify-center items-center shadow-sm">
                  {service.icon}
                </div>

                <div className="content mt-7">
                  <a
                    href="/"
                    className="text-lg hover:text-blue-600 dark:text-white dark:hover:text-blue-600 transition-all duration-500 ease-in-out font-medium"
                  >
                    {service.title}
                  </a>
                  <p className="text-slate-400 mt-3">{service.subtext}</p>

                  <div className="mt-5">
                    <a
                      href="#contact"
                      className="btn btn-a hover:text-blue-600 dark:hover:text-blue-600 after:bg-blue-600 dark:text-white transition duration-500"
                    >
                      Consult Now <i className="uil uil-arrow-down"></i>
                    </a>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
}
