import "./assets/css/tailwind.css";
import "./assets/scss/tailwind.scss";
import "./assets/scss/icons.scss";

import React, { useState } from "react";
import Navbar from "./partials/Navbar";
import Banner from "./partials/Banner";
import WhatWeDo from "./partials/WhatWeDo";
import About from "./partials/About";
import WhyAioBooking from "./partials/WhyAiobooking";
import Contact from "./partials/Contact";
import Footer from "./partials/Footer";

import { ReactComponent as CompanyIcon } from "./assets/images/svg/logo-aio.svg";
import { WhatsAppWidget } from "react-whatsapp-widget";
import "react-whatsapp-widget/dist/index.css";

export default function App() {
  const [isOpen, setMenu] = useState(true);

  const toggleMenu = () => {
    setMenu(!isOpen);
  };

  return (
    <React.Fragment>
      <Navbar isOpen={isOpen} toggleMenu={toggleMenu} />
      <Banner />
      <About />
      <WhatWeDo />
      <WhyAioBooking />
      <Contact />
      <Footer />
      <WhatsAppWidget
        CompanyIcon={CompanyIcon}
        phoneNumber="+628811278555"
        companyName="AIOBOOKING"
      />
    </React.Fragment>
  );
}
