import React from "react";

export default function Contact() {
  return (
    <>
      <section
        className="relative md:py-72 py-16 bg-gray-50 dark:bg-slate-800"
        id="contact"
      >
        <div className="container">
          <div className="grid grid-cols-1 pb-8 text-center">
            <h3 className="mb-4 md:text-4xl text-2xl font-semibold dark:text-white ">
              For more information, please contact us at hello@aiobooking.com or
              WhatsApp +628811278555
            </h3>
            <h5 className="text-xl dark:text-white">AIOBOOKING X DEV</h5>
            <p className="text-slate-400 text-lg">
              Jl. Tukad Badung 141 C, Renon, Denpasar, Denpasar Selatan, Bali
              80226
            </p>
          </div>
        </div>
      </section>
    </>
  );
}
