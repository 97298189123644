import AIOBOOKINGLOGO from "../assets/images/svg/LOGO AIO BOOKING-webp 1.webp";

export default function Navbar({ toggleMenu, isOpen }) {
  return (
    <nav className="navbar is-sticky" id="navbar">
      <div className="container flex flex-wrap items-center justify-between">
        <a className="navbar-brand" href="index.html">
          <span className="inline-block dark:hidden">
            <img src={AIOBOOKINGLOGO} className="w-48 l-dark" alt="" />
            <img src={AIOBOOKINGLOGO} className="w-48 l-light" alt="" />
          </span>
          <img
            src={AIOBOOKINGLOGO}
            className="w-48 hidden dark:inline-block"
            alt=""
          />
        </a>

        <div className="nav-icons flex items-center lg_992:order-2 ms-auto lg_992:hidden">
          <button
            type="button"
            className="collapse-btn inline-flex items-center ms-3 text-dark dark:text-white lg_992:hidden"
            onClick={toggleMenu}
          >
            <span className="sr-only">Navigation Menu</span>
            <i className="mdi mdi-menu mdi-24px"></i>
          </button>
        </div>

        <div
          className={`${
            isOpen === true ? "hidden" : "block"
          } navigation lg_992:order-1 lg_992:flex`}
          id="menu-collapse"
        >
          <ul className="navbar-nav nav-light" id="navbar-navlist">
            <a className="nav-item" href="#home">
              <span className="nav-link">Home</span>
            </a>
            <a className="nav-item" href="#about">
              <span className="nav-link">About</span>
            </a>
            <a className="nav-item" href="#whatwedo">
              <span className="nav-link">What We Do</span>
            </a>
            <a className="nav-item" href="#whyaiobooking">
              <span className="nav-link">Why aiobooking?</span>
            </a>
            <a className="nav-item" href="#contact">
              <span className="nav-link">Contact us</span>
            </a>
          </ul>
        </div>
      </div>
    </nav>
  );
}
