import React from "react";
import AIOROBOT2 from "../assets/images/Robot-AIO Booking.jpg";

export default function About() {
  return (
    <>
      <section
        className="relative md:py-24 py-16 bg-gray-50 dark:bg-slate-800"
        id="about"
      >
        <div className="container mx-auto">
          <div className="grid grid-cols-1 lg:grid-cols-12 md:grid-cols-2 gap-10 items-center">
            <div className="lg:col-span-7">
              <div className="lg:ms-7">
                <h6 className="text-blue-600 text-base font-medium uppercase mb-2">
                  About AIOBOOKING
                </h6>
                <h3 className="mb-4 md:text-2xl text-xl font-medium dark:text-white">
                  All In One Booking
                </h3>

                <p className="text-slate-400 dark:text-slate-300 max-w-2xl mx-auto">
                  <span className="underline">AIOBOOKING</span> is one of sister
                  product from PT Bali Reservasi Indonesia that designed and
                  develop to increase the hospitality business results by
                  maximazing the support from your own website which facilitated
                  with our{" "}
                  <span className="underline">Online Reservation System</span>.
                </p>
              </div>
            </div>
            <div className="lg:col-span-5">
              <div className="relative">
                <img
                  src={AIOROBOT2}
                  className="rounded-lg shadow-lg relative w-full"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
