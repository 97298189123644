import Payment from "../assets/images/svg/payment.svg";
import MarketShare from "../assets/images/svg/market-share.svg";
import Tools from "../assets/images/svg/tools.svg";

export default function WhyAioBooking() {
  return (
    <section id="whyaiobooking" className="container md:mt-24 mt-16">
      <div className="grid grid-cols-1 pb-8 text-center">
        <h6 className="text-blue-600 text-base font-medium uppercase mb-2">
          Why AIOBOOKING?
        </h6>
        <h3 className="mb-4 md:text-2xl text-xl font-medium dark:text-white">
          What Make Us Different From The Existing Systems
        </h3>
      </div>

      <div className="grid grid-cols-1 mt-8 mb-20">
        <div className="timeline relative">
          <div className="timeline-item">
            <div className="grid sm:grid-cols-2">
              <div className="">
                <div className="duration date-label-left ltr:float-right rtl:float-left md:me-7 relative">
                  <img src={Payment} className="h-72 w-72" alt="" />
                </div>
              </div>
              <div className="mt-4 md:mt-0">
                <div className="event event-description-right ltr:float-left rtl:float-right ltr:text-left rtl:text-right md:ms-7">
                  <h5 className="text-lg dark:text-white mb-1 font-medium">
                    Payment Gateway Integration
                  </h5>
                  <p className="timeline-subtitle mt-3 mb-0 text-slate-400">
                    To make the booking process as smooth as possible,
                    especially in payment process, we integrate with payment
                    gateways such as Xendit, Midtrans, and DOKU.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="timeline-item mt-5 pt-4">
            <div className="grid sm:grid-cols-2">
              <div className="md:order-1 order-2">
                <div className="event event-description-left ltr:float-left rtl:float-right ltr:text-right rtl:text-left md:me-7">
                  <h5 className="text-lg dark:text-white mb-1 font-medium">
                    Expand Your Market
                  </h5>
                  <p className="timeline-subtitle mt-3 mb-0 text-slate-400">
                    Increase your market share. Making an accommodation's
                    promotion or enticing customers with discounts and perks.
                    Data can also be easily analyzed for your future marketing
                    campaign. Get it to save time and money on marketing.
                  </p>
                </div>
              </div>
              <div className="md:order-2 order-1">
                <div className="duration duration-right md:ms-7 relative">
                  <img src={MarketShare} className="h-60 w-60" alt="" />
                </div>
              </div>
            </div>
          </div>

          <div className="timeline-item mt-5 pt-4">
            <div className="grid sm:grid-cols-2">
              <div className="mt-4 mt-sm-0">
                <div className="duration date-label-left ltr:float-right rtl:float-left md:me-7 relative">
                  <img src={Tools} className="h-72 w-72" alt="" />
                </div>
              </div>
              <div className="mt-4 mt-sm-0">
                <div className="event event-description-right ltr:float-left rtl:float-right ltr:text-left rtl:text-right md:ms-7">
                  <h5 className="text-lg dark:text-white mb-1 font-medium">
                    Smart Tools for Your Product
                  </h5>
                  <p className="timeline-subtitle mt-3 mb-0 text-slate-400">
                    Using our online booking engine. You can quickly allocate
                    time and create a decent timetable. and get scheduled on any
                    device. We also simplify inventory management and the
                    withdrawal process. Use our booking engine to handle your
                    goods smartly.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
