import PeopleDealing from "../assets/images/bg/people-dealing.jpg";

export default function Banner() {
  return (
    <section
      style={{ backgroundImage: `url(${PeopleDealing})` }}
      className="py-36 lg:py-64 w-full table relative bg-center bg-cover"
      id="home"
    >
      <div className="absolute inset-0 bg-black opacity-80"></div>
      <div className="container relative">
        <div className="grid grid-cols-1 mt-12 text-center">
          <h4 className="text-white lg:text-6xl text-4xl lg:leading-normal leading-normal font-medium mb-7 position-relative">
            Manage Reservations Easier <br />
            with{" "}
            <span className="typewrite relative text-type-element">
              AIOBOOKING
            </span>
          </h4>

          <p className="text-white opacity-50 mb-0 mx-auto max-w-2xl text-lg">
            Whether you are a ferry operator, an independent property or part of
            hotel chain. Focus on your guest and have our automated systems do
            the work for you.
          </p>

          <div className="relative mt-10">
            <a
              href="#about"
              className="btn bg-blue-600 hover:bg-blue-700 border-blue-600 hover:border-blue-700 text-white rounded-md"
            >
              Read More
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}
